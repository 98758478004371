@import 'src/styles/variables';

.Header {
  padding: 0.5rem;
  border-bottom: 1px solid $color-gray_light;
  background: $color-white;
  margin-bottom: 1rem;

  .Nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $max-width;
    margin: auto;

    .btn-logout {
      background: none;
      border: none;
    }
  }
}
