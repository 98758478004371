@import '~bootstrap/scss/bootstrap';

@import 'variables';
@import 'buttons';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.clickable {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-left {
  text-align: left !important;
}

.text-decoration-none {
  text-decoration: none;
}

.icon-width-large {
  width: 1.5rem;
  font-size: large;
}

.icon-width-medium {
  width: 1rem;
  font-size: medium;
}

.card {
  background: $color-white;
  border: 1px solid $color-gray_light;
  box-sizing: border-box;
  box-shadow: $box-shadow;
  border-radius: 4px;
  padding: 3rem;
}

.color-blue {
  color: $color-blue_dark;
}

.color-gray {
  color: $color-gray;
}

.font-bold {
  font-weight: bold;
}

.table {
  background-color: $color-white;
  border-color: $color-gray_light;

  th:first-child,
  td:first-child {
    display: flex;
    align-items: center;
    padding-left: 2rem;
  }
  tbody {
    border-top: none !important;
  }
}

.icon-check {
  color: $color-green;
  font-size: xx-large;
}

a.anchor {
  color: $color-blue_dark;
}
