.ContainerSpinner {
  background: rgba(108, 108, 108, 0.4);
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .Spinner {
    width: 5rem;
    height: 5rem;
  }
}
