@import 'src/styles/variables';

.layout-container {
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  background-color: $color-background;

  .page-content {
    max-width: $max-width;
  }
}
