@import 'src/styles/variables';

.DeliveriesList {
  .border-bottom {
    border-bottom: 1px solid $color-gray_light;
  }

  .route-added td:first-child::before {
    content: 'N';
    background: $color-green;
  }

  .route-deleted td {
    background-color: $color-background;
    color: $color-gray;

    &:first-child::before {
      content: 'E';
      background: $color-red;
    }
  }

  .route-added td,
  .route-deleted td {
    &:first-child::before {
      margin-left: -1.5rem;
      border-radius: 4px;
      font-size: 11px;
      width: 18px;
      height: 18px;
      position: absolute;
      font-weight: bold;
      color: $color-white;
      text-align: center;
    }
  }
}
